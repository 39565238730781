<template>
  <div id="orders">
    <portal to="filter">
      <el-header class="filter-header">
        <div class="title">Order List</div>
        <div class="actions-buttons">
          <el-button type="secondary" class="btn" :class="{ 'active-filter': filterActive ? true : false }" @click="toggle"><i class="icon-filter-filter"></i> Filters</el-button>
          <el-button type="primary" class="btn" @click="setItem(null)">
            <i class="el-icon-loading" style="font-size: 18px; color: white" v-if="loading == 'openAddDialogLoading'"></i>
            <i class="icon-filter-add" v-else></i>Add New Order</el-button
          >
        </div>
      </el-header>
    </portal>
    <div class="filter-container">
      <div class="header">
        <div class="title">Filters</div>
        <el-button type="danger" class="btn" @click="clearFilter">
          <i class="icon-filter-close"></i>
          Clear Filter
        </el-button>
      </div>
      <div class="all-inputs">
        <el-row :gutter="36">
          <el-col :sm="8">
            <el-input placeholder="Search" v-model="filter.SearchTerm" prefix-icon="el-icon-search" clearable></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="36">
          <el-col class="input-container" :sm="8">
            <div class="text">Date</div>
            <el-date-picker format="dd-MM-yyyy" prefix-icon="icon-calendarplus" v-model="dateRange" type="daterange" align="right" unlink-panels range-separator="To" start-placeholder="Start date" end-placeholder="End date" clearable> </el-date-picker>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text">Status</div>
            <el-select v-model="filter.SubOrderStatus" placeholder="Select" clearable>
              <el-option v-for="(item, index) in getEnums.find((x) => x.Title == 'SubOrderStatus').Enums" :key="item.Key + index" :label="item.DisplayName" :value="item.Key"> </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="table-container">
      <div class="view-info">
        <p>You are viewing page {{ filter.Page }} out of a total of {{ totalCount.PageCount }} pages. (Total Records: {{ totalCount.TotalCount }})</p>
        <el-button class="refresh-btn" @click="arrowsRotate"> <i class="icon-arrows-rotate-solid"></i></el-button>
      </div>
      <el-table v-if="orderList" :data="orderList" style="width: 100%" v-on:sort-change="sortHandler">
        <el-table-column prop="OrderNo" label="Order No" width="110" sortable="custom"></el-table-column>
        <el-table-column label="SKU" width="120" sortable="custom">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.SubOrder.Deliveries" :key="index"> {{ item.SKU }} <br /> </span>
          </template>
        </el-table-column>
        <el-table-column label="Product Name">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.SubOrder.Deliveries" :key="index"> {{ item.ProductName }} <br /> </span>
          </template>
        </el-table-column>
        <el-table-column label="Customer">
          <template slot-scope="scope"> {{ scope.row.Customer.CompanyName }} </template>
        </el-table-column>
        <el-table-column prop="SubOrder.Warehouse.Title" label="Warehouse Name"> </el-table-column>
        <el-table-column prop="SubOrder.Agent.Title" label="Agent"> </el-table-column>
        <el-table-column prop="EstimatedDeliveryDate" label="Estimated Delivery Date" sortable="custom" width="220">
          <template slot-scope="scope">
            <span>{{ $moment(scope.row.SubOrder.EstimatedDeliveryDate).format("DD-MM-YYYY") }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Status" label="Status" width="150">
          <template slot-scope="scope">
            <div class="status" :class="{ success: scope.row.SubOrder.Status == 0, info: scope.row.SubOrder.Status == 1, danger: scope.row.SubOrder.Status == 2, warning: scope.row.SubOrder.Status == 3 }">
              <span v-if="getEnums">{{ getEnumsDisplay("SubOrderStatus", scope.row.SubOrder.Status) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Preview" width="110">
          <template slot-scope="scope">
            <el-button class="popover-eye" @click="exportDeliveryNote(scope.row.ID)" slot="reference">
              <i class="el-icon-loading" style="font-size: 18px; color: blue" v-if="loading == 'exportLoading' + scope.row.ID"></i>
              <i class="icon-eye-popover" v-else></i>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="160">
          <template slot-scope="scope">
            <el-dropdown>
              <el-button type="primary"> Actions<i class="el-icon-arrow-down el-icon--right"></i></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span class="view-edit" @click="ordersView(scope.row.ID)"><i class="icon-pen-to-square-solid"></i>View</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="[2, 3].includes(orderList.find((x) => x.ID == scope.row.ID).SubOrder.Status)">
                  <span class="view-edit transit" @click="changeStatusInTransit(scope.row.ID)"><i class="icon-truck"></i>In Transit</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="[1].includes(orderList.find((x) => x.ID == scope.row.ID).SubOrder.Status)">
                  <span class="view-edit stuck" @click="changeStatusStuck(scope.row.ID)"><i class="icon-ban-solid"></i>Stuck</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-bottom">
        <div class="info">
          <span>Show</span>
          <el-select v-model="filter.PageSize">
            <el-option label="12" :value="12"></el-option>
            <el-option label="24" :value="24"></el-option>
            <el-option label="36" :value="36"></el-option>
            <el-option label="100" :value="100"></el-option>
            <el-option label="200" :value="200"></el-option>
          </el-select>
          <span>entries</span>
        </div>
        <el-pagination layout="prev, pager, next" :current-page.sync="filter.Page" :page-size="filter.PageSize" :total="totalCount.TotalCount"> </el-pagination>
      </div>
    </div>
    <el-dialog top="5vh" :visible.sync="ordersDialogVisible" width="60%" :destroy-on-close="true" :show-close="false" :close-on-click-modal="false">
      <addOrUpdateOrdersDialog :form="form" v-if="ordersDialogVisible" @close="closeAddDialog()" />
    </el-dialog>
    <el-dialog top="5vh" :visible.sync="ordersViewDialogVisible" width="60%" :destroy-on-close="true" :show-close="false" :close-on-click-modal="false">
      <orderViewDialog :id="setId" v-if="ordersViewDialogVisible" @close="closeViewDialog()" />
    </el-dialog>
  </div>
</template>

<script>
import addOrUpdateOrdersDialog from "../../components/addOrUpdateOrdersDialog.vue";
import orderViewDialog from "../../components/orderViewDialog.vue";
export default {
  components: {
    addOrUpdateOrdersDialog,
    orderViewDialog,
  },
  data() {
    return {
      setId: null,
      dateRange: null,
      form: {},
      ordersDialogVisible: false,
      ordersViewDialogVisible: false,
      filterActive: null,
      loading: false,
      timeout: null,
    };
  },
  async beforeMount() {
    await this.$store.dispatch("getOrderList");
    await this.$store.dispatch("getAgentList");
  },
  mounted() {
    this.jQuery(".filter-container").hide();
  },
  methods: {
    async sortHandler(params) {
      console.log(params);
      this.filter.SortBy = params.prop == undefined ? "SKU" : params.prop;
      console.log(this.filter.SortBy)
      this.filter.SortDirection = params.order == "descending" ? 1 : 0;
    },
    exportDeliveryNote(id) {
      this.loading = "exportLoading" + id;
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var token = this.$cookies.get("token")
        // window.open(`http://188.166.145.241/delivery-note.html?id=${id}&subOrderNo=${no}&token=${token}`);
        window.open(`https://pdf.imageus.dev/?url=https://portal2.packagingsource.co.uk/delivery-note.html?id=${id}=idtoken-seperator=${token}`);

        this.loading = false;
      }, 500);
    },
    async changeStatusInTransit(id) {
      var payload = {
        OrderId: id,
      };

      this.$confirm("Are you sure you want to change the status to in transit?", "Warning", {
        confirmButtonText: "Yes",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/Order/InTransit", payload);
          if (res.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            await this.$store.dispatch("getOrderList");
          }
        })
        .catch(() => {});
    },
    ordersView(id) {
      console.log(id);
      this.$nextTick(() => {
        this.setId = id;
        console.log(this.setId);
      });
      this.ordersViewDialogVisible = true;
      console.log(this.setId, id);
    },

    async changeStatusStuck(id) {
      var payload = {
        OrderId: id,
        Status: 2,
      };

      this.$confirm("Are you sure you want to change the status to stuck?", "Warning", {
        confirmButtonText: "Yes",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/Order/ChangeStatus", payload);
          if (res.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            await this.$store.dispatch("getOrderList");
          }
        })
        .catch(() => {});
    },
    async arrowsRotate() {
      await this.$store.dispatch("getOrderList");
    },
    toggle() {
      this.filterActive = !this.filterActive;
      this.jQuery(".filter-container").slideToggle();
    },
    closeAddDialog(id) {
      this.setId = id;
      this.ordersDialogVisible = false;
    },
    closeViewDialog() {
      this.ordersViewDialogVisible = false;
    },
    setItem(id) {
      if (id != null) {
        this.loading = "openAddDialogLoading" + id;
      } else {
        this.loading = "openAddDialogLoading";
      }
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.setId = id;
        this.$store.commit("setOrderValidation", []);
        this.ordersDialogVisible = true;
        this.loading = false;
      }, 500);
    },
    clearFilter() {
      this.filter.SubOrderStatus = null;
      this.dateRange = null;
      this.filter.SearchTerm = null;
    },
  },
  computed: {
    getAllCustomers() {
      return this.$store.getters.getCustomersList;
    },
    agentList() {
      return this.$store.getters.getAgentList;
    },
    orderList() {
      // var orderList = this.$store.getters.getOrderList;
      // var arr = [];
      // orderList.map((x) => {
      //   arr.push({ ...x.SubOrder, Customer: x.Customer, OrderId: x.SubOrder.ID, OrderNo: x.OrderNo });
      // });
      // return arr;
      return this.$store.getters.getOrderList;
    },
    totalCount() {
      return this.$store.getters.getOrderTotalCount;
    },
    filter() {
      return this.$store.getters.getOrderFilter;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
  },
  watch: {
    dateRange: {
      deep: true,
      handler() {
        if (this.dateRange != null && this.dateRange.length > 0) {
          this.filter.OrderStartDate = this.dateRange[0];
          this.filter.OrderEndDate = this.dateRange[1];
        } else {
          this.filter.OrderStartDate = null;
          this.filter.OrderEndDate = null;
          this.dateRange = null;
        }
      },
    },
    filter: {
      deep: true,
      handler: async function () {
        await this.$store.dispatch("getOrderList");
      },
    },
  },
};
</script>

<style></style>
